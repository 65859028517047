import { FC } from 'react'
import styles from './VanityFooter.module.scss'
import { VanityFooterProps } from './VanityFooter.types'
import { Img } from 'components/basic/Img'
import { Type } from 'components/Type'
import { Button } from 'components/Phantom/Button'

export const VanityFooter: FC<VanityFooterProps> = (props) => {
	const { title } = props
	const partnerName = title.split('sleeps')[0]
	const message = 'sleeps' + title.split('sleeps')[1]
	return (
		<section className={styles.container}>
			<div className={styles.wrapper}>
				<header>
					<Type.Headline2 animateOnScroll>
						{title ?? (
							<>
								{partnerName}
								{message}
							</>
						)}
					</Type.Headline2>
					<Type.Body1
						className={styles.quote}
						animateOnScroll
					>
						{props.quote}
					</Type.Body1>

					<div className={styles.cta_container}>
						<Button.White
							id={'prospecting-hero-shop'}
							href={props.ctaLabel}
						>
							{props.ctaLabel}
						</Button.White>
					</div>
				</header>
				<Img
					src={props.sources?.[0].src ?? 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bfcm_2024/bfcm-prospecting-new.jpg'}
					alt={''}
					objectFit={'cover'}
					className={styles.image}
				/>
			</div>
		</section>
	)
}
