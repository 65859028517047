import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import { Main } from 'layouts'
import { SiteSettings } from 'config/types'
import { AdtPixel } from 'components/_pixels'
import { RootStore } from 'stores'
import { PersonalSleepConnected } from 'components/Phantom/_sections/PersonalSleep'
import { FeaturedReviews } from 'components/Phantom/_sections/FeaturedReviews'
import { ScrollSequenceStatic } from 'components/Phantom/_sections/ScrollSequenceStatic'
import { Img, ImgBackground } from 'components/basic/Img'
import styles from './Vanity.module.scss'
import { Waypoint } from 'react-waypoint'
import { amViewedShopSectionOnVanity } from 'events/amplitude'
import { BetterSleepDrawers, PersonalizedSleep } from 'pageCores/prospectingCMin/localComponents'
import { VanityHero } from 'components/VanityHero'
import { VanityFooter } from 'components/VanityFooter'
import { PriceManager } from 'prices'
import { Awards } from 'pageCores/homepage/components/Awards'
import { NewStandardConnected } from 'components/Phantom/_sections/NewStandard'
import { ClientOnly } from 'components/ClientOnly'
import { ConnectedShop } from 'components/Phantom/_pageCompositions/Shop'

interface Props {
	settings: SiteSettings
	renderFinancing: boolean
	maxMonthsAtZeroInterest: number
	monthlyPriceFormatted: string
	rootStore: RootStore
	cartLoading?: boolean
}

const customImgs = {
	healf: {
		hero: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/healf-new-hero-2024-09.jpg',
		heroMob: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/healf-new-hero-mobile-2024-09.jpg',
		logo: 'https://eightsleep-react-assets.s3.us-east-2.amazonaws.com/assets/Healf_NewLogo+1.svg',
	},
	lifetime: {
		hero: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/lifetime-heroimg.jpeg',
		heroMob: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/lifetimeheromob.jpg',
		logo: 'https://d115sb6i1ixllw.cloudfront.net/assets/lifetime-white-logo.svg',
	},
	wynwood: {
		hero: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/lifetime-heroimg.jpeg',
		heroMob: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/lifetimeheromob.jpg',
		logo: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/wynwood_logo_white.svg',
	},
	goodbounce: {
		hero: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/lifetime-heroimg.jpeg',
		heroMob: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/lifetimeheromob.jpg',
		logo: 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/goodbounce_logo_white.svg',
	},
}
const CustomHero = ({ page, homeSettings: { heroTitle } }: SiteSettings) => {
	if (customImgs[page]) {
		return (
			<section className={styles.hero_container}>
				<ImgBackground
					src={customImgs[page].hero}
					alt={''}
					sources={[{ mediaQuery: '(max-width: 1023px)', src: customImgs[page].heroMob }]}
					lazy={false}
					dprHeight={1000}
					className={styles.hero_image}
				>
					<div className={styles.shade}></div>
					<div className={styles.hero_content}>
						<div className={styles.custom_logos}>
							<Img
								alt={'Eight Sleep Logo'}
								src={'https://eightsleep.imgix.net/Logo_White.svg?10878386720812330155'}
								className={styles.logo}
							/>
							<Img
								alt={'Strava lock badge'}
								src={'https://d115sb6i1ixllw.cloudfront.net/assets/strava/strava-lock-badge.svg'}
								className={styles.lock_badge}
							/>
							<Img
								src={customImgs[page].logo}
								alt={'Strava logo'}
								className={styles.strava_badge}
							/>
						</div>
						{heroTitle && <div className={styles.hero_title}>{heroTitle}</div>}
					</div>
				</ImgBackground>
			</section>
		)
	}
	return null
}

export const Vanity: FC<Props> = (props) => {
	const { settings, rootStore } = props
	const { priceStore, settingsStore } = rootStore
	const discountFormatted = PriceManager.formatPriceToCurrencyNoDecimal(priceStore.getUpToDiscountNumber, priceStore.currency)
	const metric = settingsStore.metricRegion

	const [viewed, setViewed] = useState(false)

	useEffect(() => {
		rootStore.shopStore.setShowShopBanner(true)
	}, [])

	return (
		<Main>
			{CustomHero(settings) ?? (
				<VanityHero
					title={settings.heroSettings?.heading || `${settings.partnerName} sleeps on the Pod`}
					author={settings.heroSettings?.description}
					quote={settings.homeSettings.heroTitle}
					boldQuote
					phantomImage={settings.phantomImage}
					bgImg={settings.heroSettings?.bgImage}
					sources={settings.heroSettings?.sources}
					alt={settings.emailCapSettings?.imageAlt || ''}
					ctaLabel={`Shop now`}
					className="outer-margins-bottom"
					discount={discountFormatted}
					metric={metric}
					partnerName={settings.partnerName}
				/>
			)}
			<NewStandardConnected />
			<div>
				<Waypoint
					onEnter={() => {
						if (viewed) return
						rootStore.shopStore.trackViewShopPage()
						amViewedShopSectionOnVanity(settings.partnerName)
						setViewed(true)
					}}
					scrollableAncestor={'window'}
				/>
			</div>
			<ClientOnly>
				<ConnectedShop />
			</ClientOnly>
			<PersonalSleepConnected
				showExtraSleep={false}
				eyebrow={'Put your sleep on Autopilot'}
				header={'Your perfect sleep conditions, all night'}
				subHeader={"The Pod personalizes your side of the bed, creating the ultimate environment for deep, restorative sleep—even if you and your partner can't agree on the perfect temperature."}
				cta={{
					href: '#shop',
					text: 'Shop now',
				}}
				theme={'white'}
			/>
			<PersonalizedSleep theme={'light'} />
			{/*<SplitFeatureFinancing />*/}
			<BetterSleepDrawers theme={'white'} />
			<FeaturedReviews />
			<VanityFooter
				title={settings.preFooterSettings?.title || `${settings.partnerName} sleeps on the Pod`}
				quote={settings.preFooterSettings?.paragraphs?.[0] ?? `${settings.partnerName} swears by the Pod cover to get better sleep. Learn more for yourself.`}
				phantomImage={settings.phantomImage}
				bgImg={settings.heroSettings?.bgImage}
				sources={settings.preFooterSettings?.sources ?? settings.heroSettings?.sources}
				alt={settings.emailCapSettings?.imageAlt || ''}
				ctaLabel={`Get ${discountFormatted} off the Pod`}
				subtitle="The Pod"
				subheadline={`Unlock ${discountFormatted} off`}
				metric={metric}
			/>
			<ScrollSequenceStatic title={`Shop Pod 4 Ultra in our ${settings.saleName}`} />
			<Awards />
			<AdtPixel id="6563" />
		</Main>
	)
}
