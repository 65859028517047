import { FC } from 'react'
import styles from './VanityHero.module.scss'
import { VanityHeroProps } from './VanityHero.types'
import { Img } from 'components/basic/Img'
import { Type } from 'components/Type'
import { Button } from 'components/Phantom/Button'

export const VanityHero: FC<VanityHeroProps> = (props) => {
	const {} = props

	const getTitle = () => {
		if (props.quote) {
			return props.title
		}

		return null
	}

	const getQuote = () => {
		if (props.quote) {
			return props.quote
		}

		return props.title
	}

	const getAuthor = (): string | null => {
		if (!props.quote) return ''
		if (!props.author) {
			if (props.partnerName) return `${props.partnerName}`
			return null
		}

		const flattedAuthor = props.author.flat().join(', ')
		if (!flattedAuthor.length) {
			if (props.partnerName) return `${props.partnerName}`
			return null
		}

		return flattedAuthor
	}

	return (
		<section
			className={styles.container}
			id={'vanity-hero'}
		>
			<Img
				src={props.sources?.[0].src ?? 'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/bfcm_2024/bfcm-prospecting-new.jpg'}
				alt={''}
				objectFit={'cover'}
				className={styles.image}
			/>
			<header>
				<Type.Eyebrow
					as={'h1'}
					className={styles.eyebrow}
					data-accent={!props.quote}
					animateOnScroll
				>
					{getTitle()}
				</Type.Eyebrow>
				<figure className={styles.quote}>
					<blockquote>
						<Type.Headline4
							as={'p'}
							animateOnScroll
						>
							<span dangerouslySetInnerHTML={{ __html: getQuote() }} />
						</Type.Headline4>
					</blockquote>
					<figcaption>
						<Type.Body1
							className={styles.author}
							animateOnScroll
						>
							{getAuthor()}
						</Type.Body1>
					</figcaption>
				</figure>

				<div className={styles.cta_container}>
					<Button.White
						id={'prospecting-hero-shop'}
						href={'#shop'}
						disableSmoothScroll
						aElement
					>
						{props.ctaLabel}
					</Button.White>
				</div>
			</header>
		</section>
	)
}
